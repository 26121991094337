import { inject } from "vue";

export default () => {
  const moment = inject("$moment");
  const convertDateFormat = (date) => {
    return moment(date).format("LL");
  };
  const convertDateTimeFormat = (date) => {
    return moment(date).format("LLL");
  };
  const convertDateReportFormat = (from, to) => {
    const dateFrom = moment(from);
    const dateTo = moment(to);
    const ModifiedDateFrom = dateFrom.format("MMMM YYYY");
    const ModifiedDateTo = dateTo.format("MMMM YYYY");
    return `${ModifiedDateFrom} - ${ModifiedDateTo}`;
  };
  const convertAmountFormat = (amount) => {
    let result;
    let dataType = typeof amount;

    if (dataType === "number") {
      result = amount.toLocaleString("en-PH", {
        style: "currency",
        currency: "PHP",
      });
    } else {
      const amountNumber = parseFloat(amount.replace(/,/g, ""));
      result = amountNumber.toLocaleString("en-PH", {
        style: "currency",
        currency: "PHP",
      });
    }

    return `${result}`;
  };
  const convertNumberFormat = (number) => {
    const amountNumber = Number(number.replace(/,/g, ""));
    const formattedAmount = Math.floor(amountNumber).toLocaleString("en-US");
    return formattedAmount;
  };
  return {
    convertDateFormat,
    convertDateTimeFormat,
    convertAmountFormat,
    convertNumberFormat,
    convertDateReportFormat,
  };
};
