import moment from "moment";
import { ref } from "vue";

export default () => {
  const selectedFrequency = ref("");
  const dateFrom = ref();
  const dateTo = ref();
  const frequencyHandler = () => {
    switch (selectedFrequency.value) {
      case "3":
        dateFrom.value = moment()
          .subtract(3, "M")
          .startOf("month")
          .format("YYYY-MM-DD");
        dateTo.value = moment()
          .subtract(1, "months")
          .endOf("month")
          .format("YYYY-MM-DD");
        break;
      case "6":
        dateFrom.value = moment()
          .subtract(6, "M")
          .startOf("month")
          .format("YYYY-MM-DD");
        dateTo.value = moment()
          .subtract(1, "months")
          .endOf("month")
          .format("YYYY-MM-DD");
        break;
      case "12":
        dateFrom.value = moment()
          .subtract(12, "M")
          .startOf("month")
          .format("YYYY-MM-DD");
        dateTo.value = moment()
          .subtract(1, "months")
          .endOf("month")
          .format("YYYY-MM-DD");
        break;
      default:
        dateFrom.value = moment().startOf("year").format("YYYY-MM-DD");
        dateTo.value = moment().format("YYYY-MM-DD");
    }
  };
  return {
    selectedFrequency,
    dateFrom,
    dateTo,
    frequencyHandler,
  };
};
