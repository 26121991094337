<template>
  <ul>
    <li v-for="(item, index) in itemReports" :key="index">
      <router-link :to="item.route" class="card">
        <span class="logo gradient">
          <component :is="item.logo" class="svg"></component>
        </span>
        <h4>{{ item.title }}</h4>
        <h5>{{ item.description }}</h5>
      </router-link>
    </li>
  </ul>
</template>

<script>
import BuildingSVG from "@/components/svgs/BuildingIcon.vue";
import AvailmentSVG from "@/components/svgs/MedicalIcon.vue";
import { reactive } from "vue";
export default {
  components: {
    BuildingSVG,
    AvailmentSVG,
  },
  setup() {
    const itemReports = reactive([
      {
        title: "Company Utilization Reports",
        description: "consist payments and availments",
        logo: "BuildingSVG",
        route: "/reports/company/utilization",
      },
      {
        title: "Availment Report",
        description: "consist detailed availments",
        logo: "AvailmentSVG",
        route: "/reports/company/availments",
      },
      {
        title: "Company Plans & Benefits",
        description: "consist detailed company premium benefits",
        logo: "BuildingSVG",
        route: "/reports/company/plans",
      },
      {
        title: "All Company Utilization Report",
        description: "consist all company overall utilization",
        logo: "BuildingSVG",
        route: "/reports/company/allur",
      },
      {
        title: "Availment with SOA",
        description: "consist of detailed OP & IP availment report",
        logo: "BuildingSVG",
        route: "/reports/company/availmentsoa",
      },
    ]);

    return {
      itemReports,
    };
  },
};
</script>
<style scoped>
ul {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 14px;
}
ul > li .card {
  min-width: 290px;
  height: 190px;
  background-color: var(--light-color);
  display: flex;
  gap: 6px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
    rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  border-radius: 12px;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
}
ul > li .card .logo {
  aspect-ratio: 1 / 1;
  width: 65px;
  height: 64px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 8px;
}
ul > li .card h5 {
  padding: 0px 10px;
  text-align: center;
}
.gradient {
  background: linear-gradient(
    -160deg,
    var(--primary-gradient) 0%,
    var(--secondary-gradient) 90%
  );
}
.svg {
  fill: var(--light-color);
}
ul > li .card h4 {
  font-weight: 500;
  color: var(--font-color-one);
}
ul > li .card h5 {
  font-weight: 400;
  color: var(--font-color-three);
}
@media only screen and (min-width: 320px) and (max-width: 440px) {
  ul {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 14px;
  }
}
</style>
