<template>
  <div class="gridview-card">
    <NotFoundSVG />
    <h4>No data has been found.</h4>
    <h5>Kindly adjust the filter dates and try again</h5>
  </div>
</template>
<script>
import NotFoundSVG from "@/components/svgs/NotFoundIcon.vue";
export default {
  components: { NotFoundSVG },
  setup() {},
};
</script>
<style scoped>
.gridview-card {
  min-height: 410px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 4px;
}
.gridview-card h4 {
  color: var(--font-color-four);
  font-size: 16px;
  font-weight: 500;
}
.gridview-card h5 {
  color: var(--font-color-three);
  font-size: 14px;
  font-weight: 400;
}
</style>
