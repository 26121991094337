import { createApp } from "vue";
import axios from "./utils/axios";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Swal from "sweetalert2";
import moment from "moment";
import "animate.css";
import "./assets/css/global.css";
import "./assets/css/table.css";
import GlobalModal from "@/components/common/HeroModal.vue";
import GlobalSpinning from "./components/common/HeroSpinning.vue";
import HeroNoDataGrid from "@/components/common/HeroNoDataGrid.vue";
import HeroNotFoundDataGrid from "@/components/common/HeroNotFoundDataGrid.vue";
const app = createApp(App);

app.use(store);
app.use(router);
app.provide("$axios", axios);
app.provide("$router", router);
app.provide("$store", store);
app.provide("$swal", Swal);
app.provide("$moment", moment);
app.component("global-modal", GlobalModal);
app.component("global-loading", GlobalSpinning);
app.component("NoDataTable", HeroNoDataGrid);
app.component("NotFoundDataTable", HeroNotFoundDataGrid);
app.mount("#app");
