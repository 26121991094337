import Swal from "sweetalert2";

export default () => {
  const ErrorAlertNotification = (error) => {
    let errorMessage;
    errorMessage = error.response.data.message;

    Swal.fire({
      title: "Internal Server Error",
      text: errorMessage,
      imageUrl: require("../assets/images/internal-error.png"),
      imageAlt: "Custom image",
    });
  };
  return {
    ErrorAlertNotification,
  };
};
