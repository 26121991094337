<template>
  <div class="gridview-card">
    <DatabaseSVG />
    <h4>There is no available data yet.</h4>
    <h5>Filter the data to get started.</h5>
  </div>
</template>
<script>
import DatabaseSVG from "@/components/svgs/DatabaseIcon.vue";
export default {
  components: { DatabaseSVG },
  setup() {},
};
</script>
<style scoped>
.gridview-card {
  min-height: 410px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 4px;
}
.gridview-card h4 {
  color: var(--font-color-four);
  font-size: 16px;
  font-weight: 500;
}
.gridview-card h5 {
  color: var(--font-color-three);
  font-size: 14px;
  font-weight: 400;
}
</style>
