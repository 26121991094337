<template>
  <h4>Version 1.0.0</h4>
</template>
<style scoped>
h4 {
  margin: 10px 0px;
  font-weight: 500;
  font-size: 12px;
  color: var(--font-color-four);
}
</style>
