<template>
  <div class="wrapper">
    <h5>
      All data reports have been successfully synchronized as of January 14,
      2025. This report offers accurate and complete insights, reflecting the
      most up-to-date information for [specific purpose].
    </h5>
    <div class="container-divider">
      <DataGridCard />
    </div>
  </div>
</template>
<script>
import DataGridCard from "@/components/partials/reports/DataCard.vue";
export default {
  components: { DataGridCard },
  setup() {},
};
</script>
<style scoped>
.panel h5 {
  font-weight: 400;
  font-size: 13px;
  color: var(--font-color-three);
}
</style>
