<template>
  <AccordionLayout :items="accordionItems" :hideItem="hideContent">
    <template v-slot:content-0>
      <li class="col">
        <span>Plan Code</span>
        <span>{{ item.Plancode }}</span>
      </li>
      <li>
        <span>Premium</span>
        <span>{{ convertAmountFormat(item.Premium) }}</span>
      </li>
      <li>
        <span>Risk Classfication</span>
        <span>{{ item["Risk Classification"] }}</span>
      </li>
      <li>
        <span>Payment Method</span>
        <span>{{ item.PaymentMethod }}</span>
      </li>
    </template>
    <template v-slot:content-1>
      <li>
        <span>Annual Benefit Limit</span>
        <span>{{ convertAmountFormat(item.MaxCoverage) }}</span>
      </li>

      <li v-show="item.IllnessPerYear_IP">
        <span>Illness per confinement per year</span>
        <span>{{ convertAmountFormat(item.IllnessPerYear_IP) }}</span>
      </li>

      <li v-show="item['HIB Benefit']">
        <span>Hospital Income Benefit</span>
        <span>{{ convertAmountFormat(item["HIB Benefit"]) }}</span>
      </li>

      <li v-show="item.Emergency_IP">
        <span>Emergency</span>
        <span>{{ convertAmountFormat(item.Emergency_IP) }}</span>
      </li>

      <li>
        <span>Room Type</span>
        <span>{{ item.RoomAndBoard }}</span>
      </li>
    </template>
    <template v-slot:content-2>
      <li v-show="item.OutPatient">
        <span>Out-Patient</span>
        <span>{{ convertAmountFormat(item.OutPatient) }}</span>
      </li>

      <li v-show="item.PerQuarter_OP">
        <span>Per quarter</span>
        <span>{{ convertAmountFormat(item.PerQuarter_OP) }}</span>
      </li>

      <li v-show="item.Emergency_OP">
        <span>Emergency</span>
        <span>{{ convertAmountFormat(item.Emergency_OP) }}</span>
      </li>
    </template>

    <template v-slot:content-3>
      <li v-show="item.Accidental">
        <span>Accidental</span>
        <span>{{ convertAmountFormat(item.Accidental) }}</span>
      </li>
      <li v-show="item.Surgical">
        <span>Surgical</span>
        <span>{{ convertAmountFormat(item.Surgical) }}</span>
      </li>
      <li v-show="item.ICU">
        <span>ICU</span>
        <span>{{ convertAmountFormat(item.ICU) }}</span>
      </li>
      <li v-show="item.Cancer">
        <span>Cancer</span>
        <span>{{ convertAmountFormat(item.Cancer) }}</span>
      </li>
    </template>

    <template v-slot:content-4>
      <li>
        <div>
          <ul>
            <li>1. Free & Unlimited consultation & dental examinations</li>
            <li>
              2. Simple tooth extraction, except surgery for impaction
              (unlimited/maximum of 3 teeth per day)
            </li>
            <li>3. Temporary fillings (up tp 3 times year)</li>
            <li>
              4. Twice a year oral prophylaxis (mild to moderate) which can be
              availed of after three (3) months of membership (premium paid)/
              succeeding may be availed of six (6) months thereafter
            </li>
          </ul>
        </div>
      </li>
    </template>
    <template v-slot:content-5>
      <li>
        <span>After six (6) months of continuous membership.</span>
      </li>
      <li>
        <ul>
          <span>Procedure:</span>
          <li>a. Physcial Check Up/ Consultation</li>
          <li>b. Chest X-ray</li>
          <li>c. Urinalysis</li>
          <li>d. Fecalysis</li>
          <li>e. Complete Blood Count (CBC)</li>
        </ul>
      </li>
    </template>
    <template v-slot:content-6>
      <li>
        <span>Maximum of </span>
      </li>
    </template>
    <template v-slot:content-7>
      <li v-show="item['Insurance']">
        <span>Insurance Provider</span>
        <span>{{ item["Insurance"] }}</span>
      </li>
      <li v-show="item['Life Ben.']">
        <span>Life Insurance</span>
        <span>{{ convertAmountFormat(item["Life Ben."]) }}</span>
      </li>
      <li v-show="item['Burial Ben.']">
        <span>Burial</span>
        <span>{{ convertAmountFormat(item["Burial Ben."]) }}</span>
      </li>
      <li v-show="item['ADD Ben.']">
        <span>Accidental Death</span>
        <span>{{ convertAmountFormat(item["ADD Ben."]) }}</span>
      </li>
      <li v-show="item['ADDD Benefit']">
        <span>Accidental Death and Dismemberment</span>
        <span>{{ convertAmountFormat(item["ADDD Benefit"]) }}</span>
      </li>
      <li v-show="item['Unprovoked Benefit']">
        <span>Unprovoked Murder and Assualt (UMA)</span>
        <span>{{ convertAmountFormat(item["Unprovoked Benefit"]) }}</span>
      </li>
      <li v-show="item['Terminal Illness Benefit']">
        <span>Terminal Illness Benefit (TIB)</span>
        <span>{{ convertAmountFormat(item["Terminal Illness Benefit"]) }}</span>
      </li>
      <li v-show="item['Motorcycle Benefit']">
        <span>Motorcycle Benefit</span>
        <span>{{ convertAmountFormat(item["Motorcycle Benefit"]) }}</span>
      </li>
    </template>
    <template v-slot:content-8>
      <li>
        <span>IWC Share</span>
        <span>{{ convertAmountFormat(item["IWCShare"]) }}</span>
      </li>
      <li v-show="item['VAT']">
        <span>Value Added Tax</span>
        <span>{{ convertAmountFormat(item["VAT"]) }}</span>
      </li>
      <li v-show="item['Life Prem.']">
        <span>Life Premium</span>
        <span>{{ convertAmountFormat(item["Life Prem."]) }}</span>
      </li>
      <li v-show="item['Burial Prem.']">
        <span>Burial Premium</span>
        <span>{{ convertAmountFormat(item["Burial Prem."]) }}</span>
      </li>
      <li v-show="item['ADD Prem.']">
        <span>Accidental Death Premium</span>
        <span>{{ convertAmountFormat(item["ADD Prem."]) }}</span>
      </li>
      <li v-show="item['ADDD Premium']">
        <span>Accidental Death and Dismemberment Premium</span>
        <span>{{ convertAmountFormat(item["ADDD Premium"]) }}</span>
      </li>
      <li v-show="item['Unprovoked Premium']">
        <span>Unprovoked Murder and Assualt (UMA) Premium</span>
        <span>{{ convertAmountFormat(item["Unprovoked Premium"]) }}</span>
      </li>
      <li v-show="item['HIB Premium']">
        <span>Hospital Income Premium</span>
        <span>{{ convertAmountFormat(item["HIB Premium"]) }}</span>
      </li>
      <li v-show="item['Motorcycle Premium']">
        <span>Motorcycle Premium</span>
        <span>{{ convertAmountFormat(item["Motorcycle Premium"]) }}</span>
      </li>
    </template>
  </AccordionLayout>
</template>

<script>
import CryptoJS from "crypto-js";
import { useRoute } from "vue-router";
import UsedFormatUtils from "@/utils/format";
import AccordionLayout from "@/components/common/HeroAccordion.vue";
import { computed, onMounted, ref } from "vue";
export default {
  components: {
    AccordionLayout,
  },
  setup() {
    const route = useRoute();
    const planItem = ref([]);
    const valueAdded = ref(null);
    const hideContent = ref([]);
    const { convertAmountFormat } = UsedFormatUtils();
    const accordionItems = ref([
      { title: "PLAN INFORMATION", content: "Content 0" },
      { title: "IN-PATIENT", content: "Content 1" },
      { title: "OUT-PATIENT", content: "Content 2" },
      { title: "OTHER BENEFITS", content: "Content 3" },
      { title: "DENTAL CARE", content: "Content 4" },
      { title: "ANNUAL PHYSICAL EXAMINATION", content: "Content 5" },
      {
        title: "REIMBURSEMENT FOR PRESCRIPTIVE MEDICINES",
        content: "Content 6",
      },
      {
        title: "FINANCIAL ASSISTANCE",
        content: "Content 7",
      },
      {
        title: "COSTING",
        content: "Content 8",
      },
    ]);
    const dataItem = ref();
    onMounted(() => {
      const decryptData = (encryptedData) => {
        const bytes = CryptoJS.AES.decrypt(
          encryptedData,
          process.env.VUE_APP_SECRET_KEY
        );
        return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      };

      const result = route.query.item ? decryptData(route.query.item) : null;
      dataItem.value = result;

      if (
        !result.Accidental &&
        !result.Surgical &&
        !result.ICU &&
        !result.Cancer
      ) {
        hideContent.value.push("Content 3");
      }

      if (result.Dental === "") {
        hideContent.value.push("Content 4");
      }

      if (result.APE === "") {
        hideContent.value.push("Content 5");
      }
    });
    const item = computed(() => {
      return dataItem.value;
    });

    return {
      accordionItems,
      planItem,
      valueAdded,
      hideContent,
      convertAmountFormat,
      dataItem,
      item,
    };
  },
};
</script>
<style scoped>
li {
  display: flex;
  justify-content: space-between;
  margin: 15px;
  font-size: 15px;
}
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
}

@media only screen and (max-width: 430px) {
  li {
    margin: 0px;
    font-size: 13px;
  }
}
</style>
