<template>
  <div class="wrapper">
    <div class="logo">
      <img :src="require('@/assets/images/iwc-header.png')" alt="icon" />
    </div>
    <h3>Admin Management Portal</h3>
    <form @submit.prevent="submitHandler">
      <div class="input-field">
        <userSVG class="icon-user" />
        <input
          class="input"
          type="text"
          placeholder="Username"
          v-model="username"
          required
        />
      </div>
      <div class="input-field">
        <SecureSVG class="icon-lock" />
        <input
          class="input"
          type="password"
          placeholder="Password"
          v-model="password"
          autocomplete="on"
          required
        />
      </div>
      <div class="loading-container input-field">
        <global-loading v-if="isLoading" />
        <input
          type="submit"
          class="btn-submit gradient"
          :value="!isLoading ? 'Login' : ''"
        />
      </div>

      <div class="alert error" v-show="isAlertPopUp">
        {{ isAlertPopUpMessage }}
      </div>
    </form>
    <HeroVersion />
  </div>
</template>

<script>
import HeroVersion from "@/components/common/HeroVersion.vue";
import UserSVG from "@/components/svgs/UserIcon.vue";
import SecureSVG from "@/components/svgs/LockIcon.vue";
import { toRefs, reactive, inject, ref } from "vue";
export default {
  components: {
    UserSVG,
    SecureSVG,
    HeroVersion,
  },
  setup() {
    const axios = inject("$axios");
    const router = inject("$router");
    const store = inject("$store");
    const isAlertPopUp = ref(false);
    const isAlertPopUpMessage = ref(null);
    const isLoading = ref(false);
    const formData = reactive({
      username: null,
      password: null,
    });
    const submitHandler = async () => {
      try {
        isLoading.value = true;
        setTimeout(async () => {
          let result = await axios.post(`/portal/login`, formData);
          if (result.status === 201) {
            localStorage.setItem(
              `${process.env.VUE_APP_AUTH_TOKEN}_XXX`,
              result.data.data
            );
            store.commit("getAdminLogDetails", result.data.details);
            return router.push("/dashboard");
          } else {
            isAlertPopUp.value = true;
            isLoading.value = false;
            isAlertPopUpMessage.value = result.data.message;
          }
          setTimeout(() => {
            isAlertPopUp.value = false;
          }, 5000);
        }, 2000);
      } catch (error) {
        store.commit("errorHandler", error);
      }
    };

    return {
      ...toRefs(formData),
      submitHandler,
      isAlertPopUp,
      isAlertPopUpMessage,
      isLoading,
    };
  },
};
</script>

<style scoped>
.wrapper {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.logo {
  display: flex;
  justify-content: center;
  align-items: center;
}
.logo img {
  height: 110px;
  width: 460px;
}
h3 {
  font-weight: 450;
  font-size: 28px;
  color: var(--font-color-one);
  padding: 25px;
  text-align: center;
}
.input-field {
  position: relative;
  margin: 10px 0;
}
.input-field .icon-user,
.input-field .icon-lock {
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
  height: 20px;
  width: 20px;
  fill: var(--font-color-one);
}
.input-field .input {
  height: 42px;
  border: none;
  min-width: 420px;
  padding-left: 40px;
  border-radius: 10px;
  color: var(--lightFont);
  font-size: 14px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
  background: rgba(255, 255, 255, 1);
  margin: 2px 0px;
}
.btn-submit {
  font-size: 16px;
  height: 45px;
}
@media only screen and (min-width: 320px) and (max-width: 440px) {
  .logo img {
    height: 85%;
    width: 85%;
  }
  .input-field .input {
    min-width: 300px;
  }
}
</style>
