<template>
  <div class="filter-action">
    <select
      v-model="paginationItem.PageSize"
      @change="$emit('selectedHandler', paginationItem.PageSize)"
    >
      <option v-for="(item, index) in filterLimit" :key="index" :value="item">
        {{ item }}
      </option>
    </select>
  </div>
</template>
<script>
import UsedPaginationUtils from "@/utils/pagination";
export default {
  setup() {
    const { paginationItem, filterLimit } = UsedPaginationUtils();

    return {
      paginationItem,
      filterLimit,
    };
  },
};
</script>
<style scoped>
.filter-action {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 14px 0px;
}
.filter-action select {
  color: var(--font-color-four);
  font-size: 14px;
  border-radius: 8px;
  width: 65px;
  box-sizing: border-box;
  padding: 8px 12px;
  border-color: #b1b1b1;
}
</style>
