<template>
  <NoDataTable v-if="!dataDetails.companyname" />
  <div v-else-if="dataDetails.companyname && dataDetails.status !== 201">
    <div class="header-title">
      <h3>{{ dataDetails.companyname }}</h3>
      <h4>
        {{ dataDetails.report.value }}
      </h4>
      <h4>
        {{ convertDateReportFormat(dataDetails.dateFrom, dataDetails.dateTo) }}
      </h4>
    </div>

    <HeroTablePage @selectedHandler="getDataSelected" />
    <div class="main-table">
      <table>
        <thead>
          <tr>
            <th class="col-span-max">Company</th>
            <th class="col-span-1">Approval No.</th>
            <th class="col-span-5">Member Name</th>
            <th class="col-span-3">Admit Date</th>
            <th class="col-span-3">Discharge Date</th>
            <th class="col-span-max">Provider Name</th>
            <th class="col-span-2">Availment Type</th>
            <th class="col-span-3">Out-Patient Type</th>
            <th class="col-span-2">Guaranteed Amount</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in modifiedDataHandler" :key="index">
            <td class="freeze">{{ item["Classification"] }}</td>
            <td>{{ item["ApprovalNo"] }}</td>
            <td>{{ item["MemberName"] }}</td>
            <td>{{ convertDateFormat(item["AdmitDate"]) }}</td>
            <td>{{ convertDateFormat(item["DischargeDate"]) }}</td>
            <td>{{ item["ProviderName"] }}</td>
            <td>{{ item["Availment Type"] }}</td>
            <td>{{ item["OPType"] }}</td>
            <td class="amount-alignment">
              {{ convertAmountFormat(item["Cov. Amount"]) }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <PaginationGrid
      :totalPages="totalPages"
      :currentPage="paginationItem.currentPage"
      @update:currentPage="handlePageChange"
    />
  </div>
  <div v-else>
    <div class="header-title">
      <h3>{{ dataDetails.companyname }}</h3>
      <h4>
        {{ dataDetails.report.value }}
      </h4>
      <h4>
        {{ convertDateReportFormat(dataDetails.dateFrom, dataDetails.dateTo) }}
      </h4>
    </div>
    <NotFoundDataTable />
  </div>
</template>
<script>
import PaginationGrid from "@/components/common/HeroPagination.vue";
import HeroTablePage from "@/components/common/HeroTablePage.vue";
import UsedFormatUtils from "@/utils/format";
import UsedPaginationUtils from "@/utils/pagination";
import { computed, watch } from "vue";
export default {
  components: { PaginationGrid, HeroTablePage },
  props: {
    dataResult: Object,
    dataDetails: Object,
    searchData: String,
  },
  setup(props) {
    const { convertDateReportFormat, convertDateFormat, convertAmountFormat } =
      UsedFormatUtils();

    const { paginationItem, filterLimit } = UsedPaginationUtils();

    const modifiedDataHandler = computed(() => {
      const dataItems = props.dataResult || [];
      const search = (props.searchData || "").toLowerCase().trim();
      const pageSize = paginationItem.PageSize || 10;
      const currentPage = paginationItem.currentPage || 1;

      const filteredData = dataItems.filter((item) => {
        return item.MemberName?.toLowerCase().includes(search);
      });

      const startIdx = (currentPage - 1) * pageSize;
      const endIdx = currentPage * pageSize;
      const paginatedData = filteredData.slice(startIdx, endIdx);
      return paginatedData;
    });

    const totalPages = computed(() =>
      Math.ceil(props.dataResult.length / paginationItem.PageSize)
    );

    watch(() => props.searchData, modifiedDataHandler);
    const handlePageChange = (page) => {
      paginationItem.currentPage = page;
      watch(() => paginationItem.currentPage, modifiedDataHandler);
    };
    const getDataSelected = (data) => {
      paginationItem.PageSize = data;
      watch(() => paginationItem.PageSize, modifiedDataHandler);
    };
    return {
      convertDateReportFormat,
      convertDateFormat,
      convertAmountFormat,
      modifiedDataHandler,
      paginationItem,
      totalPages,
      handlePageChange,
      getDataSelected,
      filterLimit,
    };
  },
};
</script>
<style scoped>
.header-title {
  margin-top: 18px;
}
.header-title h3 {
  color: #f37420;
}
.header-title h4 {
  color: #4e4e4e;
  font-weight: 700;
}
.main-table {
  max-width: 1550px;
  overflow: auto;
}
.col-span-1 {
  min-width: 140px;
}
.col-span-2 {
  min-width: 180px;
}
.col-span-3 {
  min-width: 200px;
}
.col-span-4 {
  min-width: 250px;
}
.col-span-5 {
  min-width: 300px;
}
.col-span-max {
  min-width: 540px;
}
td {
  text-align: left;
}

.amount-alignment {
  text-align: right;
}
</style>
