<template>
  <NoDataTable v-if="!dataResult.length" />
  <div v-else>
    <div class="header-title">
      <h3>All Company Utilization Report</h3>
      <h4>
        Year
        {{ dataDetails.year }}
      </h4>
    </div>
    <div class="main-table">
      <table>
        <thead>
          <tr>
            <th rowspan="3">Company</th>
            <th colspan="4">January</th>
            <th colspan="4">February</th>
            <th colspan="4">March</th>
            <th colspan="4">April</th>
            <th colspan="4">May</th>
            <th colspan="4">June</th>
            <th colspan="4">July</th>
            <th colspan="4">August</th>
            <th colspan="4">September</th>
            <th colspan="4">October</th>
            <th colspan="4">November</th>
            <th colspan="4">December</th>
          </tr>
          <tr>
            <!-- January -->
            <th rowspan="3">Active Mem.</th>
            <th rowspan="3">IWC Share</th>
            <th rowspan="3">Availment</th>
            <th rowspan="3">Percentage</th>
            <!-- February -->
            <th rowspan="3">Active Mem.</th>
            <th rowspan="3">IWC Share</th>
            <th rowspan="3">Availment</th>
            <th rowspan="3">Percentage</th>
            <!-- March -->
            <th rowspan="3">Active Mem.</th>
            <th rowspan="3">IWC Share</th>
            <th rowspan="3">Availment</th>
            <th rowspan="3">Percentage</th>
            <!-- April -->
            <th rowspan="3">Active Mem.</th>
            <th rowspan="3">IWC Share</th>
            <th rowspan="3">Availment</th>
            <th rowspan="3">Percentage</th>
            <!-- May -->
            <th rowspan="3">Active Mem.</th>
            <th rowspan="3">IWC Share</th>
            <th rowspan="3">Availment</th>
            <th rowspan="3">Percentage</th>
            <!-- June -->
            <th rowspan="3">Active Mem.</th>
            <th rowspan="3">IWC Share</th>
            <th rowspan="3">Availment</th>
            <th rowspan="3">Percentage</th>
            <!-- July -->
            <th rowspan="3">Active Mem.</th>
            <th rowspan="3">IWC Share</th>
            <th rowspan="3">Availment</th>
            <th rowspan="3">Percentage</th>
            <!-- August -->
            <th rowspan="3">Active Mem.</th>
            <th rowspan="3">IWC Share</th>
            <th rowspan="3">Availment</th>
            <th rowspan="3">Percentage</th>
            <!-- September -->
            <th rowspan="3">Active Mem.</th>
            <th rowspan="3">IWC Share</th>
            <th rowspan="3">Availment</th>
            <th rowspan="3">Percentage</th>
            <!-- October -->
            <th rowspan="3">Active Mem.</th>
            <th rowspan="3">IWC Share</th>
            <th rowspan="3">Availment</th>
            <th rowspan="3">Percentage</th>
            <!-- November -->
            <th rowspan="3">Active Mem.</th>
            <th rowspan="3">IWC Share</th>
            <th rowspan="3">Availment</th>
            <th rowspan="3">Percentage</th>
            <!-- December -->
            <th rowspan="3">Active Mem.</th>
            <th rowspan="3">IWC Share</th>
            <th rowspan="3">Availment</th>
            <th rowspan="3">Percentage</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in modifiedDataHandler" :key="index">
            <td class="col-span-1">{{ item["COMPNAME"] }}</td>
            <!-- January -->
            <td class="col-span-2">{{ item["jan_actives"] }}</td>
            <td class="col-span-2">{{ item["jan"] }}</td>
            <td class="col-span-2">{{ item["jan_avail"] }}</td>
            <td class="col-span-2">{{ item["jan_percnt"] }}</td>
            <!-- February -->
            <td class="col-span-2">{{ item["feb_actives"] }}</td>
            <td class="col-span-2">{{ item["feb"] }}</td>
            <td class="col-span-2">{{ item["feb_avail"] }}</td>
            <td class="col-span-2">{{ item["feb_percnt"] }}</td>
            <!-- March -->
            <td class="col-span-2">{{ item["mar_actives"] }}</td>
            <td class="col-span-2">{{ item["mar"] }}</td>
            <td class="col-span-2">{{ item["mar_avail"] }}</td>
            <td class="col-span-2">{{ item["mar_percnt"] }}</td>
            <!-- April -->
            <td class="col-span-2">{{ item["apr_actives"] }}</td>
            <td class="col-span-2">{{ item["apr"] }}</td>
            <td class="col-span-2">{{ item["apr_avail"] }}</td>
            <td class="col-span-2">{{ item["apr_percnt"] }}</td>
            <!-- May -->
            <td class="col-span-2">{{ item["may_actives"] }}</td>
            <td class="col-span-2">{{ item["may"] }}</td>
            <td class="col-span-2">{{ item["may_avail"] }}</td>
            <td class="col-span-2">{{ item["may_percnt"] }}</td>
            <!-- June -->
            <td class="col-span-2">{{ item["jun_actives"] }}</td>
            <td class="col-span-2">{{ item["jun"] }}</td>
            <td class="col-span-2">{{ item["jun_avail"] }}</td>
            <td class="col-span-2">{{ item["jun_percnt"] }}</td>
            <!-- July -->
            <td class="col-span-2">{{ item["jul_actives"] }}</td>
            <td class="col-span-2">{{ item["jul"] }}</td>
            <td class="col-span-2">{{ item["jul_avail"] }}</td>
            <td class="col-span-2">{{ item["jul_percnt"] }}</td>
            <!-- August -->
            <td class="col-span-2">{{ item["aug_actives"] }}</td>
            <td class="col-span-2">{{ item["aug"] }}</td>
            <td class="col-span-2">{{ item["aug_avail"] }}</td>
            <td class="col-span-2">{{ item["aug_percnt"] }}</td>
            <!-- September -->
            <td class="col-span-2">{{ item["sep_actives"] }}</td>
            <td class="col-span-2">{{ item["sep"] }}</td>
            <td class="col-span-2">{{ item["sep_avail"] }}</td>
            <td class="col-span-2">{{ item["sep_percnt"] }}</td>
            <!-- October -->
            <td class="col-span-2">{{ item["oct_actives"] }}</td>
            <td class="col-span-2">{{ item["oct"] }}</td>
            <td class="col-span-2">{{ item["oct_avail"] }}</td>
            <td class="col-span-2">{{ item["oct_percnt"] }}</td>
            <!-- November -->
            <td class="col-span-2">{{ item["nov_actives"] }}</td>
            <td class="col-span-2">{{ item["nov"] }}</td>
            <td class="col-span-2">{{ item["nov_avail"] }}</td>
            <td class="col-span-2">{{ item["nov_percnt"] }}</td>
            <!-- December -->
            <td class="col-span-2">{{ item["decm_actives"] }}</td>
            <td class="col-span-2">{{ item["decm"] }}</td>
            <td class="col-span-2">{{ item["dec_avail"] }}</td>
            <td class="col-span-2">{{ item["dec_percnt"] }}</td>
          </tr>
        </tbody>
        <tfoot>
          <tr class="footer">
            <td colspan="1">TOTAL:</td>
            <!-- January -->
            <td>{{ JAN_activemem(modifiedDataHandler) }}</td>
            <td>{{ JAN_remittance(modifiedDataHandler) }}</td>
            <td>{{ JAN_availment(modifiedDataHandler) }}</td>
            <td>{{ JAN_percent(modifiedDataHandler) }}</td>
            <!-- February -->
            <td>{{ FEB_activemem(modifiedDataHandler) }}</td>
            <td>{{ FEB_remittance(modifiedDataHandler) }}</td>
            <td>{{ FEB_availment(modifiedDataHandler) }}</td>
            <td>{{ FEB_percent(modifiedDataHandler) }}</td>
            <!-- March -->
            <td>{{ MAR_activemem(modifiedDataHandler) }}</td>
            <td>{{ MAR_remittance(modifiedDataHandler) }}</td>
            <td>{{ MAR_availment(modifiedDataHandler) }}</td>
            <td>{{ MAR_percent(modifiedDataHandler) }}</td>
            <!-- April -->
            <td>{{ APR_activemem(modifiedDataHandler) }}</td>
            <td>{{ APR_remittance(modifiedDataHandler) }}</td>
            <td>{{ APR_availment(modifiedDataHandler) }}</td>
            <td>{{ APR_percent(modifiedDataHandler) }}</td>
            <!-- May -->
            <td>{{ MAY_activemem(modifiedDataHandler) }}</td>
            <td>{{ MAY_remittance(modifiedDataHandler) }}</td>
            <td>{{ MAY_availment(modifiedDataHandler) }}</td>
            <td>{{ MAY_percent(modifiedDataHandler) }}</td>
            <!-- June -->
            <td>{{ JUN_activemem(modifiedDataHandler) }}</td>
            <td>{{ JUN_remittance(modifiedDataHandler) }}</td>
            <td>{{ JUN_availment(modifiedDataHandler) }}</td>
            <td>{{ JUN_percent(modifiedDataHandler) }}</td>
            <!-- July -->
            <td>{{ JUL_activemem(modifiedDataHandler) }}</td>
            <td>{{ JUL_remittance(modifiedDataHandler) }}</td>
            <td>{{ JUL_availment(modifiedDataHandler) }}</td>
            <td>{{ JUL_percent(modifiedDataHandler) }}</td>
            <!-- August -->
            <td>{{ AUG_activemem(modifiedDataHandler) }}</td>
            <td>{{ AUG_remittance(modifiedDataHandler) }}</td>
            <td>{{ AUG_availment(modifiedDataHandler) }}</td>
            <td>{{ AUG_percent(modifiedDataHandler) }}</td>
            <!-- September -->
            <td>{{ SEP_activemem(modifiedDataHandler) }}</td>
            <td>{{ SEP_remittance(modifiedDataHandler) }}</td>
            <td>{{ SEP_availment(modifiedDataHandler) }}</td>
            <td>{{ SEP_percent(modifiedDataHandler) }}</td>
            <!-- October -->
            <td>{{ OCT_activemem(modifiedDataHandler) }}</td>
            <td>{{ OCT_remittance(modifiedDataHandler) }}</td>
            <td>{{ OCT_availment(modifiedDataHandler) }}</td>
            <td>{{ OCT_percent(modifiedDataHandler) }}</td>
            <!-- November -->
            <td>{{ NOV_activemem(modifiedDataHandler) }}</td>
            <td>{{ NOV_remittance(modifiedDataHandler) }}</td>
            <td>{{ NOV_availment(modifiedDataHandler) }}</td>
            <td>{{ NOV_percent(modifiedDataHandler) }}</td>
            <!-- December -->
            <td>{{ DEC_activemem(modifiedDataHandler) }}</td>
            <td>{{ DEC_remittance(modifiedDataHandler) }}</td>
            <td>{{ DEC_availment(modifiedDataHandler) }}</td>
            <td>{{ DEC_percent(modifiedDataHandler) }}</td>
          </tr>
        </tfoot>
      </table>
    </div>
  </div>
</template>
<script>
import UsedFormatUtils from "@/utils/format";
import UsedPaginationUtils from "@/utils/pagination";
import { computed, watch } from "vue";
export default {
  props: {
    dataResult: Object,
    dataDetails: Object,
    searchData: String,
  },
  setup(props) {
    const { convertDateReportFormat, convertDateFormat, convertAmountFormat } =
      UsedFormatUtils();

    const { paginationItem, filterLimit } = UsedPaginationUtils();

    const modifiedDataHandler = computed(() => {
      let dataItems = props.dataResult;
      let search = (props.searchData || "").toLowerCase().trim();

      let resultData = dataItems.filter((val) => {
        return val.COMPNAME?.toLowerCase().includes(search);
      });
      return resultData;
    });

    const JAN_activemem = (val) => {
      let resultData = val;
      let total = resultData
        .map((val) => parseFloat(val["jan_actives"].replace(/,/g, "")) || 0)
        .reduce((sum, total) => sum + total, 0)
        .toLocaleString("en-US");
      return total;
    };
    const JAN_remittance = (val) => {
      let resultData = val;
      let total = resultData
        .map((val) => parseFloat(val["jan"].replace(/,/g, "")) || 0)
        .reduce((sum, total) => sum + total, 0)
        .toLocaleString("en-US");
      return total;
    };
    const JAN_availment = (val) => {
      let resultData = val;
      let total = resultData
        .map((val) => parseFloat(val["jan_avail"].replace(/,/g, "")) || 0)
        .reduce((sum, total) => sum + total, 0)
        .toLocaleString("en-US");
      return total;
    };
    const JAN_percent = (val) => {
      let resultData = val;
      let totalAvailmentAmount = resultData
        .map((val) => parseFloat(val["jan_avail"].replace(/,/g, "")) || 0)
        .reduce((sum, total) => sum + total, 0);

      let totalAmountRemittance = resultData
        .map((val) => parseFloat(val["jan"].replace(/,/g, "")) || 0)
        .reduce((sum, total) => sum + total, 0);

      let result = `${(
        (totalAvailmentAmount / totalAmountRemittance) *
        100
      ).toFixed(2)}%`;

      return result;
    };
    const FEB_activemem = (val) => {
      let resultData = val;
      let total = resultData
        .map((val) => parseFloat(val["feb_actives"].replace(/,/g, "")) || 0)
        .reduce((sum, total) => sum + total, 0)
        .toLocaleString("en-US");
      return total;
    };
    const FEB_remittance = (val) => {
      let resultData = val;
      let total = resultData
        .map((val) => parseFloat(val["feb"].replace(/,/g, "")) || 0)
        .reduce((sum, total) => sum + total, 0)
        .toLocaleString("en-US");
      return total;
    };
    const FEB_availment = (val) => {
      let resultData = val;
      let total = resultData
        .map((val) => parseFloat(val["feb_avail"].replace(/,/g, "")) || 0)
        .reduce((sum, total) => sum + total, 0)
        .toLocaleString("en-US");
      return total;
    };
    const FEB_percent = (val) => {
      let resultData = val;
      let totalAvailmentAmount = resultData
        .map((val) => parseFloat(val["feb_avail"].replace(/,/g, "")) || 0)
        .reduce((sum, total) => sum + total, 0);

      let totalAmountRemittance = resultData
        .map((val) => parseFloat(val["feb"].replace(/,/g, "")) || 0)
        .reduce((sum, total) => sum + total, 0);

      let result = `${(
        (totalAvailmentAmount / totalAmountRemittance) *
        100
      ).toFixed(2)}%`;

      return result;
    };
    const MAR_activemem = (val) => {
      let resultData = val;
      let total = resultData
        .map((val) => parseFloat(val["mar_actives"].replace(/,/g, "")) || 0)
        .reduce((sum, total) => sum + total, 0)
        .toLocaleString("en-US");
      return total;
    };
    const MAR_remittance = (val) => {
      let resultData = val;
      let total = resultData
        .map((val) => parseFloat(val["mar"].replace(/,/g, "")) || 0)
        .reduce((sum, total) => sum + total, 0)
        .toLocaleString("en-US");
      return total;
    };
    const MAR_availment = (val) => {
      let resultData = val;
      let total = resultData
        .map((val) => parseFloat(val["mar_avail"].replace(/,/g, "")) || 0)
        .reduce((sum, total) => sum + total, 0)
        .toLocaleString("en-US");
      return total;
    };
    const MAR_percent = (val) => {
      let resultData = val;
      let totalAvailmentAmount = resultData
        .map((val) => parseFloat(val["mar_avail"].replace(/,/g, "")) || 0)
        .reduce((sum, total) => sum + total, 0);

      let totalAmountRemittance = resultData
        .map((val) => parseFloat(val["mar"].replace(/,/g, "")) || 0)
        .reduce((sum, total) => sum + total, 0);

      let result = `${(
        (totalAvailmentAmount / totalAmountRemittance) *
        100
      ).toFixed(2)}%`;

      return result;
    };
    const APR_activemem = (val) => {
      let resultData = val;
      let total = resultData
        .map((val) => parseFloat(val["apr_actives"].replace(/,/g, "")) || 0)
        .reduce((sum, total) => sum + total, 0)
        .toLocaleString("en-US");
      return total;
    };
    const APR_remittance = (val) => {
      let resultData = val;
      let total = resultData
        .map((val) => parseFloat(val["apr"].replace(/,/g, "")) || 0)
        .reduce((sum, total) => sum + total, 0)
        .toLocaleString("en-US");
      return total;
    };
    const APR_availment = (val) => {
      let resultData = val;
      let total = resultData
        .map((val) => parseFloat(val["apr_avail"].replace(/,/g, "")) || 0)
        .reduce((sum, total) => sum + total, 0)
        .toLocaleString("en-US");
      return total;
    };
    const APR_percent = (val) => {
      let resultData = val;
      let totalAvailmentAmount = resultData
        .map((val) => parseFloat(val["apr_avail"].replace(/,/g, "")) || 0)
        .reduce((sum, total) => sum + total, 0);

      let totalAmountRemittance = resultData
        .map((val) => parseFloat(val["apr"].replace(/,/g, "")) || 0)
        .reduce((sum, total) => sum + total, 0);

      let result = `${(
        (totalAvailmentAmount / totalAmountRemittance) *
        100
      ).toFixed(2)}%`;

      return result;
    };
    const MAY_activemem = (val) => {
      let resultData = val;
      let total = resultData
        .map((val) => parseFloat(val["may_actives"].replace(/,/g, "")) || 0)
        .reduce((sum, total) => sum + total, 0)
        .toLocaleString("en-US");
      return total;
    };
    const MAY_remittance = (val) => {
      let resultData = val;
      let total = resultData
        .map((val) => parseFloat(val["may"].replace(/,/g, "")) || 0)
        .reduce((sum, total) => sum + total, 0)
        .toLocaleString("en-US");
      return total;
    };
    const MAY_availment = (val) => {
      let resultData = val;
      let total = resultData
        .map((val) => parseFloat(val["may_avail"].replace(/,/g, "")) || 0)
        .reduce((sum, total) => sum + total, 0)
        .toLocaleString("en-US");
      return total;
    };
    const MAY_percent = (val) => {
      let resultData = val;
      let totalAvailmentAmount = resultData
        .map((val) => parseFloat(val["may_avail"].replace(/,/g, "")) || 0)
        .reduce((sum, total) => sum + total, 0);

      let totalAmountRemittance = resultData
        .map((val) => parseFloat(val["may"].replace(/,/g, "")) || 0)
        .reduce((sum, total) => sum + total, 0);

      let result = `${(
        (totalAvailmentAmount / totalAmountRemittance) *
        100
      ).toFixed(2)}%`;

      return result;
    };
    const JUN_activemem = (val) => {
      let resultData = val;
      let total = resultData
        .map((val) => parseFloat(val["jun_actives"].replace(/,/g, "")) || 0)
        .reduce((sum, total) => sum + total, 0)
        .toLocaleString("en-US");
      return total;
    };
    const JUN_remittance = (val) => {
      let resultData = val;
      let total = resultData
        .map((val) => parseFloat(val["jun"].replace(/,/g, "")) || 0)
        .reduce((sum, total) => sum + total, 0)
        .toLocaleString("en-US");
      return total;
    };
    const JUN_availment = (val) => {
      let resultData = val;
      let total = resultData
        .map((val) => parseFloat(val["jun_avail"].replace(/,/g, "")) || 0)
        .reduce((sum, total) => sum + total, 0)
        .toLocaleString("en-US");
      return total;
    };
    const JUN_percent = (val) => {
      let resultData = val;
      let totalAvailmentAmount = resultData
        .map((val) => parseFloat(val["jun_avail"].replace(/,/g, "")) || 0)
        .reduce((sum, total) => sum + total, 0);

      let totalAmountRemittance = resultData
        .map((val) => parseFloat(val["jun"].replace(/,/g, "")) || 0)
        .reduce((sum, total) => sum + total, 0);

      let result = `${(
        (totalAvailmentAmount / totalAmountRemittance) *
        100
      ).toFixed(2)}%`;

      return result;
    };
    const JUL_activemem = (val) => {
      let resultData = val;
      let total = resultData
        .map((val) => parseFloat(val["jul_actives"].replace(/,/g, "")) || 0)
        .reduce((sum, total) => sum + total, 0)
        .toLocaleString("en-US");
      return total;
    };
    const JUL_remittance = (val) => {
      let resultData = val;
      let total = resultData
        .map((val) => parseFloat(val["jul"].replace(/,/g, "")) || 0)
        .reduce((sum, total) => sum + total, 0)
        .toLocaleString("en-US");
      return total;
    };
    const JUL_availment = (val) => {
      let resultData = val;
      let total = resultData
        .map((val) => parseFloat(val["jul_avail"].replace(/,/g, "")) || 0)
        .reduce((sum, total) => sum + total, 0)
        .toLocaleString("en-US");
      return total;
    };
    const JUL_percent = (val) => {
      let resultData = val;
      let totalAvailmentAmount = resultData
        .map((val) => parseFloat(val["jul_avail"].replace(/,/g, "")) || 0)
        .reduce((sum, total) => sum + total, 0);

      let totalAmountRemittance = resultData
        .map((val) => parseFloat(val["jul"].replace(/,/g, "")) || 0)
        .reduce((sum, total) => sum + total, 0);

      let result = `${(
        (totalAvailmentAmount / totalAmountRemittance) *
        100
      ).toFixed(2)}%`;

      return result;
    };
    const AUG_activemem = (val) => {
      let resultData = val;
      let total = resultData
        .map((val) => parseFloat(val["aug_actives"].replace(/,/g, "")) || 0)
        .reduce((sum, total) => sum + total, 0)
        .toLocaleString("en-US");
      return total;
    };
    const AUG_remittance = (val) => {
      let resultData = val;
      let total = resultData
        .map((val) => parseFloat(val["aug"].replace(/,/g, "")) || 0)
        .reduce((sum, total) => sum + total, 0)
        .toLocaleString("en-US");
      return total;
    };
    const AUG_availment = (val) => {
      let resultData = val;
      let total = resultData
        .map((val) => parseFloat(val["aug_avail"].replace(/,/g, "")) || 0)
        .reduce((sum, total) => sum + total, 0)
        .toLocaleString("en-US");
      return total;
    };
    const AUG_percent = (val) => {
      let resultData = val;
      let totalAvailmentAmount = resultData
        .map((val) => parseFloat(val["aug_avail"].replace(/,/g, "")) || 0)
        .reduce((sum, total) => sum + total, 0);

      let totalAmountRemittance = resultData
        .map((val) => parseFloat(val["aug"].replace(/,/g, "")) || 0)
        .reduce((sum, total) => sum + total, 0);

      let result = `${(
        (totalAvailmentAmount / totalAmountRemittance) *
        100
      ).toFixed(2)}%`;

      return result;
    };
    const SEP_activemem = (val) => {
      let resultData = val;
      let total = resultData
        .map((val) => parseFloat(val["sep_actives"].replace(/,/g, "")) || 0)
        .reduce((sum, total) => sum + total, 0)
        .toLocaleString("en-US");
      return total;
    };
    const SEP_remittance = (val) => {
      let resultData = val;
      let total = resultData
        .map((val) => parseFloat(val["sep"].replace(/,/g, "")) || 0)
        .reduce((sum, total) => sum + total, 0)
        .toLocaleString("en-US");
      return total;
    };
    const SEP_availment = (val) => {
      let resultData = val;
      let total = resultData
        .map((val) => parseFloat(val["sep_avail"].replace(/,/g, "")) || 0)
        .reduce((sum, total) => sum + total, 0)
        .toLocaleString("en-US");
      return total;
    };
    const SEP_percent = (val) => {
      let resultData = val;
      let totalAvailmentAmount = resultData
        .map((val) => parseFloat(val["sep_avail"].replace(/,/g, "")) || 0)
        .reduce((sum, total) => sum + total, 0);

      let totalAmountRemittance = resultData
        .map((val) => parseFloat(val["sep"].replace(/,/g, "")) || 0)
        .reduce((sum, total) => sum + total, 0);

      let result = `${(
        (totalAvailmentAmount / totalAmountRemittance) *
        100
      ).toFixed(2)}%`;

      return result;
    };
    const OCT_activemem = (val) => {
      let resultData = val;
      let total = resultData
        .map((val) => parseFloat(val["oct_actives"].replace(/,/g, "")) || 0)
        .reduce((sum, total) => sum + total, 0)
        .toLocaleString("en-US");
      return total;
    };
    const OCT_remittance = (val) => {
      let resultData = val;
      let total = resultData
        .map((val) => parseFloat(val["oct"].replace(/,/g, "")) || 0)
        .reduce((sum, total) => sum + total, 0)
        .toLocaleString("en-US");
      return total;
    };
    const OCT_availment = (val) => {
      let resultData = val;
      let total = resultData
        .map((val) => parseFloat(val["oct_avail"].replace(/,/g, "")) || 0)
        .reduce((sum, total) => sum + total, 0)
        .toLocaleString("en-US");
      return total;
    };
    const OCT_percent = (val) => {
      let resultData = val;
      let totalAvailmentAmount = resultData
        .map((val) => parseFloat(val["oct_avail"].replace(/,/g, "")) || 0)
        .reduce((sum, total) => sum + total, 0);

      let totalAmountRemittance = resultData
        .map((val) => parseFloat(val["oct"].replace(/,/g, "")) || 0)
        .reduce((sum, total) => sum + total, 0);

      let result = `${(
        (totalAvailmentAmount / totalAmountRemittance) *
        100
      ).toFixed(2)}%`;

      return result;
    };
    const NOV_activemem = (val) => {
      let resultData = val;
      let total = resultData
        .map((val) => parseFloat(val["nov_actives"].replace(/,/g, "")) || 0)
        .reduce((sum, total) => sum + total, 0)
        .toLocaleString("en-US");
      return total;
    };
    const NOV_remittance = (val) => {
      let resultData = val;
      let total = resultData
        .map((val) => parseFloat(val["nov"].replace(/,/g, "")) || 0)
        .reduce((sum, total) => sum + total, 0)
        .toLocaleString("en-US");
      return total;
    };
    const NOV_availment = (val) => {
      let resultData = val;
      let total = resultData
        .map((val) => parseFloat(val["nov_avail"].replace(/,/g, "")) || 0)
        .reduce((sum, total) => sum + total, 0)
        .toLocaleString("en-US");
      return total;
    };
    const NOV_percent = (val) => {
      let resultData = val;
      let totalAvailmentAmount = resultData
        .map((val) => parseFloat(val["nov_avail"].replace(/,/g, "")) || 0)
        .reduce((sum, total) => sum + total, 0);

      let totalAmountRemittance = resultData
        .map((val) => parseFloat(val["nov"].replace(/,/g, "")) || 0)
        .reduce((sum, total) => sum + total, 0);

      let result = `${(
        (totalAvailmentAmount / totalAmountRemittance) *
        100
      ).toFixed(2)}%`;

      return result;
    };
    const DEC_activemem = (val) => {
      let resultData = val;
      let total = resultData
        .map((val) => parseFloat(val["decm_actives"].replace(/,/g, "")) || 0)
        .reduce((sum, total) => sum + total, 0)
        .toLocaleString("en-US");
      return total;
    };
    const DEC_remittance = (val) => {
      let resultData = val;
      let total = resultData
        .map((val) => parseFloat(val["decm"].replace(/,/g, "")) || 0)
        .reduce((sum, total) => sum + total, 0)
        .toLocaleString("en-US");
      return total;
    };
    const DEC_availment = (val) => {
      let resultData = val;
      let total = resultData
        .map((val) => parseFloat(val["dec_avail"].replace(/,/g, "")) || 0)
        .reduce((sum, total) => sum + total, 0)
        .toLocaleString("en-US");
      return total;
    };
    const DEC_percent = (val) => {
      let resultData = val;
      let totalAvailmentAmount = resultData
        .map((val) => parseFloat(val["dec_avail"].replace(/,/g, "")) || 0)
        .reduce((sum, total) => sum + total, 0);

      let totalAmountRemittance = resultData
        .map((val) => parseFloat(val["decm"].replace(/,/g, "")) || 0)
        .reduce((sum, total) => sum + total, 0);

      let result = `${(
        (totalAvailmentAmount / totalAmountRemittance) *
        100
      ).toFixed(2)}%`;

      return result;
    };

    watch(() => props.searchData);
    const handlePageChange = (page) => {
      paginationItem.currentPage = page;
      watch(() => paginationItem.currentPage);
    };
    return {
      convertDateReportFormat,
      convertDateFormat,
      convertAmountFormat,
      paginationItem,
      handlePageChange,
      filterLimit,
      modifiedDataHandler,
      JAN_activemem,
      JAN_remittance,
      JAN_availment,
      JAN_percent,
      FEB_activemem,
      FEB_remittance,
      FEB_availment,
      FEB_percent,
      MAR_activemem,
      MAR_remittance,
      MAR_availment,
      MAR_percent,
      APR_activemem,
      APR_remittance,
      APR_availment,
      APR_percent,
      MAY_activemem,
      MAY_remittance,
      MAY_availment,
      MAY_percent,
      JUN_activemem,
      JUN_remittance,
      JUN_availment,
      JUN_percent,
      JUL_activemem,
      JUL_remittance,
      JUL_availment,
      JUL_percent,
      AUG_activemem,
      AUG_remittance,
      AUG_availment,
      AUG_percent,
      SEP_activemem,
      SEP_remittance,
      SEP_availment,
      SEP_percent,
      OCT_activemem,
      OCT_remittance,
      OCT_availment,
      OCT_percent,
      NOV_activemem,
      NOV_remittance,
      NOV_availment,
      NOV_percent,
      DEC_activemem,
      DEC_remittance,
      DEC_availment,
      DEC_percent,
    };
  },
};
</script>
<style scoped>
.header-title {
  margin-top: 18px;
}
.header-title h3 {
  color: #f37420;
}
.header-title h4 {
  color: #4e4e4e;
  font-weight: 700;
}
.main-table {
  max-width: 1550px;
  overflow: auto;
}
.col-span-1 {
  min-width: 140px;
}
.col-span-2 {
  min-width: 180px;
}
.col-span-3 {
  min-width: 200px;
}
.col-span-4 {
  min-width: 250px;
}
.col-span-5 {
  min-width: 300px;
}
.col-span-max {
  min-width: 540px;
}
td {
  text-align: left;
}

.amount-alignment {
  text-align: right;
}
</style>
