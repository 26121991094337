import { ref, reactive } from "vue";

export default () => {
  const filterLimit = ref([10, 20, 30, 40, 50]);
  const paginationItem = reactive({
    currentPage: 1,
    PageSize: 10,
    totalItems: 0,
  });

  return {
    filterLimit,
    paginationItem,
  };
};
