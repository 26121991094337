<template>
  <global-modal
    :modelValue="isActive"
    :title="$route.meta.subtitle"
    @modalHandler="getData"
  >
    <template #body>
      <!-- ============================
			Start Code Design Here
		  ================================ -->
      <div class="main-modal">
        <form @submit.prevent="submitHandler">
          <div class="form-group-modal-grid">
            <label for="Date">Year</label>
            <select
              v-model="selectedFrequency"
              @change="frequencyHandler()"
              required
            >
              <option disabled value="">--Please select--</option>
              <option
                v-for="(item, index) in itemFrequency"
                :key="index"
                :value="item.description"
              >
                {{ item.description }}
              </option>
            </select>
          </div>
          <div class="form-group-modal">
            <label for="Type Report">Account Type</label>
            <select name="" id="" v-model="availmentReportType">
              <option
                v-for="(item, index) in itemReportType"
                :key="index"
                :value="item"
              >
                {{ item.value }}
              </option>
            </select>
          </div>
          <div class="form-group-modal input-field loading-container">
            <global-loading v-if="isLoading" />
            <input
              type="submit"
              class="btn-submit gradient"
              :value="!isLoading ? 'Generate' : ''"
            />
          </div>
        </form>
      </div>
    </template>
  </global-modal>
</template>
<script>
import UsedFrequencyUtils from "@/utils/frequency.js";
import { computed, inject, reactive, ref, toRefs } from "vue";
export default {
  props: {
    isModal: Boolean,
  },
  setup(props, { emit }) {
    const axios = inject("$axios");
    const { selectedFrequency, dateFrom, dateTo, frequencyHandler } =
      UsedFrequencyUtils();
    const formData = reactive({
      year: null,
    });
    const itemFrequency = ref([
      {
        description: "2023",
      },
      {
        description: "2024",
      },
      {
        description: "2025",
      },
    ]);
    const isLoading = ref(false);
    const availmentReportType = ref(null);
    const itemReportType = ref([
      { id: 1, value: "IMS Accounts" },
      { id: 2, value: "IWC Accounts" },
    ]);
    const isActive = computed(() => {
      return props.isModal;
    });
    const getData = (val) => {
      emit("updateModal", val);
    };
    const submitHandler = async () => {
      formData.year = selectedFrequency.value;
      isLoading.value = true;
      try {
        let endpoint;
        if (availmentReportType.value.value === "IMS Accounts") {
          endpoint = "/allurims";
        } else if (availmentReportType.value.value === "IWC Accounts") {
          endpoint = "/alluriwc";
        }
        const { data } = await axios.get(endpoint, {
          params: {
            year: formData.year,
          },
        });
        if (data) {
          isLoading.value = false;

          let details = {
            year: formData.year,
          };
          emit("getDataResult", data, details);
          emit("updateModal", false);
        }
      } catch (error) {
        console.error("Error fetching transactions:", error);
      }
    };

    return {
      ...toRefs(formData),
      selectedFrequency,
      dateFrom,
      dateTo,
      itemFrequency,
      itemReportType,
      isActive,
      getData,
      submitHandler,
      frequencyHandler,
      isLoading,
      availmentReportType,
    };
  },
};
</script>
<style scoped>
.autofill {
  position: relative;
}
ul {
  box-shadow: rgba(14, 63, 126, 0.04) 0px 0px 0px 1px,
    rgba(42, 51, 69, 0.04) 0px 1px 1px -0.5px,
    rgba(42, 51, 70, 0.04) 0px 3px 3px -1.5px,
    rgba(42, 51, 70, 0.04) 0px 6px 6px -3px,
    rgba(14, 63, 126, 0.04) 0px 12px 12px -6px,
    rgba(14, 63, 126, 0.04) 0px 24px 24px -12px;
  background-color: var(--light-color);
  border-radius: 4px;
  position: absolute;
  width: 545px;
  max-height: 285px;
  overflow-y: auto;
  z-index: 1000;
  left: 138px;
}
.filter-company {
  font-size: 15px;
  line-height: 22px;
  padding: 3px 8px;
  color: var(--font-color-four);
}

.filter-company:hover {
  background-color: var(--primary-gradient);
  color: var(--light-color);
}
.form-group-modal input[type="submit"] {
  color: var(--light-color);
  margin-top: 0px;
  height: 44px;
}
.loading-container {
  margin: 20px 0px;
}
</style>
