<template>
  <NoDataTable v-if="!dataResult.length" />
  <div v-else>
    <div class="header-title">
      <h3>{{ dataDetails.companyname }}</h3>
      <h4>
        {{ convertDateReportFormat(dataDetails.dateFrom, dataDetails.dateTo) }}
      </h4>
    </div>
    <div class="main-table">
      <table>
        <thead>
          <tr>
            <th rowspan="3">Particular Date</th>
            <th rowspan="3">Total Number Active</th>
            <th rowspan="3">Total IWC Shares</th>
            <th colspan="2">IN-PATIENT</th>
            <th colspan="6">OUT-PATIENT</th>
            <th colspan="2">FREE</th>
            <th colspan="2">APE</th>
            <th rowspan="3">IP + OP + APE (COUNT)</th>
            <th rowspan="3">IP + OP + APE (AMOUNT)</th>
            <th rowspan="3">IP AMOUNT %</th>
            <th rowspan="3">OP AMOUNT %</th>
            <th rowspan="3">FREE AMOUNT %</th>
            <th rowspan="3">APE AMOUNT %</th>
            <th rowspan="3">OVERALL %</th>
          </tr>
          <tr>
            <th rowspan="3">Total Count</th>
            <th rowspan="3">Total Amount</th>
            <th colspan="2">Consultation</th>
            <th colspan="2">Laboratory</th>
            <th colspan="2">Other OP</th>
            <th rowspan="3">Total Count</th>
            <th rowspan="3">Total Amount</th>
            <th rowspan="3">Total Count</th>
            <th rowspan="3">Total Amount</th>
          </tr>
          <tr>
            <th colspan="1">Total Count</th>
            <th colspan="1">Total Amount</th>
            <th colspan="1">Total Count</th>
            <th colspan="1">Total Amount</th>
            <th colspan="1">Total Count</th>
            <th colspan="1">Total Amount</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in modifiedDataHandler" :key="index">
            <td class="col-span-max">{{ item["Month"] }}</td>
            <td class="col-span-1">
              {{ convertNumberFormat(item["Active Members"]) }}
            </td>
            <td class="col-span-1">
              {{ convertAmountFormat(item["Remittance"]) }}
            </td>
            <td class="col-span-2">
              {{ convertNumberFormat(item["IPCount"]) }}
            </td>
            <td class="col-span-2">
              {{ convertAmountFormat(item["IPAmount"]) }}
            </td>
            <td class="col-span-2">
              {{ convertNumberFormat(item["Cons. Count"]) }}
            </td>
            <td class="col-span-2">
              {{ convertAmountFormat(item["Cons. Amount"]) }}
            </td>
            <td class="col-span-2">
              {{ convertNumberFormat(item["Lab. Count"]) }}
            </td>
            <td class="col-span-2">
              {{ convertAmountFormat(item["Lab. Amount"]) }}
            </td>
            <td class="col-span-2">
              {{ convertNumberFormat(item["Other OP Count"]) }}
            </td>
            <td class="col-span-2">
              {{ convertAmountFormat(item["Other OP Amount"]) }}
            </td>
            <td class="col-span-2">
              {{ convertNumberFormat(item["Free Cons. Count"]) }}
            </td>
            <td class="col-span-2">
              {{ convertAmountFormat(item["Free Cons. Amount"]) }}
            </td>
            <td class="col-span-2">
              {{ convertNumberFormat(item["APE Count"]) }}
            </td>
            <td class="col-span-2">
              {{ convertAmountFormat(item["APE Amount"]) }}
            </td>
            <td class="col-span-1">
              {{ convertNumberFormat(item["IP + OP + APE (Count)"]) }}
            </td>
            <td class="col-span-1">
              {{ convertAmountFormat(item["IP + OP + APE (Amount)"]) }}
            </td>
            <td class="col-span-1">
              {{ item["IP Amount %"] }}
            </td>
            <td class="col-span-1">
              {{ item["OP Amount %"] }}
            </td>
            <td class="col-span-1">{{ item["Free Cons. Amount %"] }}</td>
            <td class="col-span-1">{{ item["APE Amount %"] }}</td>
            <td class="col-span-1">{{ item["Overall %"] }}</td>
          </tr>
        </tbody>
        <tfoot>
          <tr class="footer">
            <td colspan="2">TOTAL</td>
            <td>{{ totalRemittance(modifiedDataHandler) }}</td>
            <td>{{ totalIPCount(modifiedDataHandler) }}</td>
            <td>{{ totalIPAmount(modifiedDataHandler) }}</td>
            <td>{{ totalConsultationCount(modifiedDataHandler) }}</td>
            <td>{{ totalConsultationAmount(modifiedDataHandler) }}</td>
            <td>{{ totalLaboratoryCount(modifiedDataHandler) }}</td>
            <td>{{ totalLaboratoryAmount(modifiedDataHandler) }}</td>
            <td>{{ totalOtherOPCount(modifiedDataHandler) }}</td>
            <td>{{ totalOtherOPAmount(modifiedDataHandler) }}</td>
            <td>{{ totalFreeCount(modifiedDataHandler) }}</td>
            <td>{{ totalFreeAmount(modifiedDataHandler) }}</td>
            <td>{{ totalAPECount(modifiedDataHandler) }}</td>
            <td>{{ totalAPEAmount(modifiedDataHandler) }}</td>
            <td>{{ totalAvailmentTypeCount(modifiedDataHandler) }}</td>
            <td>{{ totalAvailmentTypeAmount(modifiedDataHandler) }}</td>
            <td>{{ percentageIPAmount(modifiedDataHandler) }}</td>
            <td>{{ percentageOPAmount(modifiedDataHandler) }}</td>
            <td>{{ percentageFreeAmount(modifiedDataHandler) }}</td>
            <td>{{ percentageAPEAmount(modifiedDataHandler) }}</td>
            <td>{{ percentageOverAll(modifiedDataHandler) }}</td>
          </tr>
        </tfoot>
      </table>
    </div>
  </div>
</template>

<script>
import UsedFormatUtils from "@/utils/format";
import { computed, watch } from "vue";
export default {
  props: {
    dataResult: Object,
    dataDetails: Object,
    searchData: String,
  },
  setup(props) {
    const {
      convertAmountFormat,
      convertNumberFormat,
      convertDateReportFormat,
    } = UsedFormatUtils();

    const modifiedDataHandler = computed(() => {
      let dataItems = props.dataResult;
      let search = (props.searchData || "").toLowerCase().trim();

      let resultData = dataItems.filter((val) => {
        return val.Month?.toLowerCase().includes(search);
      });
      return resultData;
    });

    const totalRemittance = (val) => {
      let resultData = val;
      let total = resultData
        .map((val) => parseFloat(val.Remittance.replace(/,/g, "")) || 0)
        .reduce((sum, total) => sum + total, 0)
        .toLocaleString("en-PH", {
          style: "currency",
          currency: "PHP",
        });
      return total;
    };
    const totalIPCount = (val) => {
      let resultData = val;
      let total = resultData
        .map((val) => parseFloat(val["IPCount"].replace(/,/g, "")) || 0)
        .reduce((sum, total) => sum + total, 0)
        .toLocaleString("en-US");
      return total;
    };
    const totalIPAmount = (val) => {
      let resultData = val;
      let total = resultData
        .map((val) => parseFloat(val["IPAmount"].replace(/,/g, "")) || 0)
        .reduce((sum, total) => sum + total, 0)
        .toLocaleString("en-PH", {
          style: "currency",
          currency: "PHP",
        });
      return total;
    };
    const totalConsultationCount = (val) => {
      let resultData = val;
      let total = resultData
        .map((val) => parseFloat(val["Cons. Count"].replace(/,/g, "")) || 0)
        .reduce((sum, total) => sum + total, 0)
        .toLocaleString("en-US");
      return total;
    };

    const totalConsultationAmount = (val) => {
      let resultData = val;
      let total = resultData
        .map((val) => parseFloat(val["Cons. Amount"].replace(/,/g, "")) || 0)
        .reduce((sum, total) => sum + total, 0)
        .toLocaleString("en-PH", {
          style: "currency",
          currency: "PHP",
        });
      return total;
    };
    const totalLaboratoryCount = (val) => {
      let resultData = val;
      let total = resultData
        .map((val) => parseFloat(val["Lab. Count"].replace(/,/g, "")) || 0)
        .reduce((sum, total) => sum + total, 0)
        .toLocaleString("en-US");
      return total;
    };
    const totalLaboratoryAmount = (val) => {
      let resultData = val;
      let total = resultData
        .map((val) => parseFloat(val["Lab. Amount"].replace(/,/g, "")) || 0)
        .reduce((sum, total) => sum + total, 0)
        .toLocaleString("en-PH", {
          style: "currency",
          currency: "PHP",
        });
      return total;
    };

    const totalOtherOPCount = (val) => {
      let resultData = val;
      let total = resultData
        .map((val) => parseFloat(val["Other OP Count"].replace(/,/g, "")) || 0)
        .reduce((sum, total) => sum + total, 0)
        .toLocaleString("en-US");
      return total;
    };

    const totalOtherOPAmount = (val) => {
      let resultData = val;
      let total = resultData
        .map((val) => parseFloat(val["Other OP Amount"].replace(/,/g, "")) || 0)
        .reduce((sum, total) => sum + total, 0)
        .toLocaleString("en-PH", {
          style: "currency",
          currency: "PHP",
        });
      return total;
    };

    const totalAPECount = (val) => {
      let resultData = val;
      let total = resultData
        .map((val) => parseFloat(val["APE Count"].replace(/,/g, "")) || 0)
        .reduce((sum, total) => sum + total, 0)
        .toLocaleString("en-US");
      return total;
    };

    const totalAPEAmount = (val) => {
      let resultData = val;
      let total = resultData
        .map((val) => parseFloat(val["APE Amount"].replace(/,/g, "")) || 0)
        .reduce((sum, total) => sum + total, 0)
        .toLocaleString("en-PH", {
          style: "currency",
          currency: "PHP",
        });
      return total;
    };

    const totalFreeCount = (val) => {
      let resultData = val;
      let total = resultData
        .map(
          (val) => parseFloat(val["Free Cons. Count"].replace(/,/g, "")) || 0
        )
        .reduce((sum, total) => sum + total, 0)
        .toLocaleString("en-US");
      return total;
    };

    const totalFreeAmount = (val) => {
      let resultData = val;
      let total = resultData
        .map(
          (val) => parseFloat(val["Free Cons. Amount"].replace(/,/g, "")) || 0
        )
        .reduce((sum, total) => sum + total, 0)
        .toLocaleString("en-PH", {
          style: "currency",
          currency: "PHP",
        });
      return total;
    };

    const totalAvailmentTypeCount = (val) => {
      let resultData = val;
      let total = resultData
        .map(
          (val) =>
            parseFloat(val["IP + OP + APE (Count)"].replace(/,/g, "")) || 0
        )
        .reduce((sum, total) => sum + total, 0)
        .toLocaleString("en-US");
      return total;
    };

    const totalAvailmentTypeAmount = (val) => {
      let resultData = val;
      let total = resultData
        .map(
          (val) =>
            parseFloat(val["IP + OP + APE (Amount)"].replace(/,/g, "")) || 0
        )
        .reduce((sum, total) => sum + total, 0)
        .toLocaleString("en-PH", {
          style: "currency",
          currency: "PHP",
        });
      return total;
    };

    const percentageIPAmount = (val) => {
      let resultData = val;
      let totalAmountIP = resultData
        .map((val) => parseFloat(val["IPAmount"].replace(/,/g, "")) || 0)
        .reduce((sum, total) => sum + total, 0);

      let totalAmountRemittance = resultData
        .map((val) => parseFloat(val.Remittance.replace(/,/g, "")) || 0)
        .reduce((sum, total) => sum + total, 0);

      let result = `${((totalAmountIP / totalAmountRemittance) * 100).toFixed(
        2
      )}%`;

      return result;
    };

    const percentageOPAmount = (val) => {
      let resultData = val;

      let totalAmountConsultation = resultData
        .map((val) => parseFloat(val["Cons. Amount"].replace(/,/g, "")) || 0)
        .reduce((sum, total) => sum + total, 0);

      let totalAmountLaboratory = resultData
        .map((val) => parseFloat(val["Lab. Amount"].replace(/,/g, "")) || 0)
        .reduce((sum, total) => sum + total, 0);

      let totalAmountOthers = resultData
        .map((val) => parseFloat(val["Other OP Amount"].replace(/,/g, "")) || 0)
        .reduce((sum, total) => sum + total, 0);

      let totalAmountRemittance = resultData
        .map((val) => parseFloat(val.Remittance.replace(/,/g, "")) || 0)
        .reduce((sum, total) => sum + total, 0);

      let totalOPAmount =
        totalAmountConsultation + totalAmountLaboratory + totalAmountOthers;

      let result = `${((totalOPAmount / totalAmountRemittance) * 100).toFixed(
        2
      )}%`;
      return result;
    };

    const percentageFreeAmount = (val) => {
      let resultData = val;
      let totalFreeAmount = resultData
        .map(
          (val) => parseFloat(val["Free Cons. Amount"].replace(/,/g, "")) || 0
        )
        .reduce((sum, total) => sum + total, 0);

      let totalAmountRemittance = resultData
        .map((val) => parseFloat(val.Remittance.replace(/,/g, "")) || 0)
        .reduce((sum, total) => sum + total, 0);

      let result = `${((totalFreeAmount / totalAmountRemittance) * 100).toFixed(
        2
      )}%`;

      return result;
    };

    const percentageAPEAmount = (val) => {
      let resultData = val;
      let totalAPEAmount = resultData
        .map((val) => parseFloat(val["APE Amount"].replace(/,/g, "")) || 0)
        .reduce((sum, total) => sum + total, 0);

      let totalAmountRemittance = resultData
        .map((val) => parseFloat(val.Remittance.replace(/,/g, "")) || 0)
        .reduce((sum, total) => sum + total, 0);

      let result = `${((totalAPEAmount / totalAmountRemittance) * 100).toFixed(
        2
      )}%`;

      return result;
    };
    const percentageOverAll = (val) => {
      let resultData = val;
      let totalAvailmentAmount = resultData
        .map(
          (val) =>
            parseFloat(val["IP + OP + APE (Amount)"].replace(/,/g, "")) || 0
        )
        .reduce((sum, total) => sum + total, 0);

      let totalAmountRemittance = resultData
        .map((val) => parseFloat(val.Remittance.replace(/,/g, "")) || 0)
        .reduce((sum, total) => sum + total, 0);

      let result = `${(
        (totalAvailmentAmount / totalAmountRemittance) *
        100
      ).toFixed(2)}%`;

      return result;
    };
    watch(() => props.searchData, modifiedDataHandler);

    return {
      convertAmountFormat,
      convertNumberFormat,
      convertDateReportFormat,
      modifiedDataHandler,
      totalRemittance,
      totalIPCount,
      totalIPAmount,
      totalConsultationCount,
      totalConsultationAmount,
      totalLaboratoryCount,
      totalLaboratoryAmount,
      totalOtherOPCount,
      totalOtherOPAmount,
      totalAPECount,
      totalAPEAmount,
      totalFreeCount,
      totalFreeAmount,
      totalAvailmentTypeCount,
      totalAvailmentTypeAmount,
      percentageIPAmount,
      percentageOPAmount,
      percentageFreeAmount,
      percentageAPEAmount,
      percentageOverAll,
    };
  },
};
</script>

<style scoped>
.header-title {
  margin-top: 18px;
}
.header-title h3 {
  color: #f37420;
}
.header-title h4 {
  color: #4e4e4e;
  font-weight: 700;
}
</style>
