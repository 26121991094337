import { createRouter, createWebHashHistory } from "vue-router";
import UsedAuthIndex from "@/pages/AuthIndex.vue";
import UsedDashboardIndex from "@/pages/DashboardIndex.vue";
import UsedReportIndex from "@/pages/ReportCategoryItemIndex.vue";
import UsedReportCompanyURIndex from "@/pages/Reports/CompanyUtilizationIndex.vue";
import UsedAvailmentReportIndex from "@/pages/Reports/AvailmentReportIndex.vue";
import UsedCompanyPlansBenefits from "@/pages/Reports/CompanyPlansBenefitsIndex.vue";
import UsedCompanyPlansInfo from "@/components/partials/reports/plans-benefits/DataPlanInformation.vue";
import UsedAllCompanyURIndex from "@/pages/Reports/AllCompanyURIndex.vue";
import UsedAvailmentSOAIndex from "@/pages/Reports/AvailmentSOAIndex.vue";
import store from "../store";

const routes = [
  {
    path: "/",
    name: "login",
    component: UsedAuthIndex,
    meta: {
      main: "Login",
      title: "Login",
      subtitle: "",
      layout: "General",
      requiresAuth: false,
    },
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: UsedDashboardIndex,
    meta: {
      main: "Dashboard",
      title: "Dashboard",
      layout: "Account",
      requiresAuth: true,
    },
  },
  {
    path: "/reports",
    name: "report",
    component: UsedReportIndex,
    meta: {
      main: "Reports",
      title: "Reports Management",
      subtitle: "",
      layout: "Account",
      requiresAuth: true,
    },
  },
  {
    path: "/reports/company/utilization",
    name: "company ur",
    component: UsedReportCompanyURIndex,
    meta: {
      main: "Reports",
      title: "Reports Management",
      subtitle: "Company Utilization Reports",
      layout: "Account",
      requiresAuth: true,
    },
  },
  {
    path: "/reports/company/availments",
    name: "availment report",
    component: UsedAvailmentReportIndex,
    meta: {
      main: "Reports",
      title: "Reports Management",
      subtitle: "Company Availment Report",
      layout: "Account",
      requiresAuth: true,
    },
  },
  {
    path: "/reports/company/plans",
    name: "company plans and benefits",
    component: UsedCompanyPlansBenefits,
    meta: {
      main: "Reports",
      title: "Reports Management",
      subtitle: "Company Plans & Benefits",
      layout: "Account",
      requiresAuth: true,
    },
  },
  {
    path: "/reports/company/plans/details/:id",
    name: "PlanDetails",
    component: UsedCompanyPlansInfo,
    meta: {
      main: "Reports",
      title: "Reports Management",
      subtitle: "Company Plans & Benefits",
      layout: "Account",
      requiresAuth: true,
    },
  },
  {
    path: "/reports/company/allur",
    name: "all companies utilization report",
    component: UsedAllCompanyURIndex,
    meta: {
      main: "Reports",
      title: "Reports Management",
      subtitle: "All Company Utilization Report",
      layout: "Account",
      requiresAuth: true,
    },
  },
  {
    path: "/reports/company/availmentsoa",
    name: "out-patient detailed availment report",
    component: UsedAvailmentSOAIndex,
    meta: {
      main: "Reports",
      title: "Reports Management",
      subtitle: "Availment with SOA",
      layout: "Account",
      requiresAuth: true,
    },
  },
];

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  document.title = `Uitilization Reports`;
  let isAuthenticate = store.state.isAuthorized;

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!isAuthenticate) {
      next({ path: "/" });
    } else {
      next();
    }
  } else {
    if (to.name === "login" && isAuthenticate) {
      next({ path: "/dashboard" });
    } else {
      next();
    }
  }
});

export default router;
