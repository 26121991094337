<template>
  <div class="accordion">
    <div v-for="(item, index) in items" :key="index" class="accordion-item">
      <div v-if="!hideItem.includes(item.content)">
        <button @click="toggle(index)" class="accordion-header">
          {{ item.title }}
          <ExpandSVG v-if="!activeIndices.includes(index)" class="icon" />
          <HideSVG v-else class="icon" />
        </button>
        <div v-if="activeIndices.includes(index)" class="accordion-content">
          <slot :name="'content-' + index">{{ item.content }}</slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ExpandSVG from "@/components/svgs/ExpandIcon.vue";
import HideSVG from "@/components/svgs/HideIcon.vue";
import { ref } from "vue";

export default {
  components: {
    ExpandSVG,
    HideSVG,
  },
  props: {
    items: {
      type: Array,
      required: true,
      default: () => [],
    },
    hideItem: {
      type: Object,
    },
  },
  setup() {
    const activeIndices = ref([]);

    const toggle = (index) => {
      if (activeIndices.value.includes(index)) {
        activeIndices.value = activeIndices.value.filter((i) => i !== index);
      } else {
        //activeIndices.value.push(index);
        activeIndices.value = [index];
      }
    };

    return {
      activeIndices,
      toggle,
    };
  },
};
</script>

<style>
.accordion {
  width: 100%;
  max-width: 800px;
  margin: 20px auto;
  overflow: hidden;
  background-color: var(--bg-main-color-web);
}

.accordion-item {
  margin-bottom: 12px;
}

.accordion-item:last-child {
  border-bottom: none;
}

.accordion-header {
  background: #d1d0d0;
  color: var(--font-color-four);
  padding: 15px 20px;
  cursor: pointer;
  text-align: left;
  border: none;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
  transition: background 0.3s ease, color 0.3s ease;
}

.accordion-content {
  padding: 10px 20px;
  background: var(--light-color);
  color: #333;
  animation: slideDown 0.3s ease;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
}
.icon {
  height: 24px;
  width: 24px;
}
@keyframes slideDown {
  from {
    max-height: 0;
    opacity: 0;
  }
  to {
    max-height: 1000px;
    opacity: 1;
  }
}
</style>
