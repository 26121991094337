<template>
  <ul>
    <li v-for="(item, index) in itemReports" :key="index">
      <router-link :to="item.route" class="card">
        <span class="logo gradient">
          <component :is="item.logo" class="svg"></component>
        </span>
        <h4>{{ item.title }}</h4>
        <h5>{{ item.description }}</h5>
      </router-link>
    </li>
  </ul>
</template>

<script>
import BuildingSVG from "@/components/svgs/BuildingIcon.vue";
import { reactive } from "vue";
export default {
  components: {
    BuildingSVG,
  },
  setup() {
    const itemReports = reactive([
      {
        title: "Company Utilization Reports",
        description: "consist payment and availment",
        logo: "BuildingSVG",
        route: "/reports/company/utilization",
      },
    ]);

    return {
      itemReports,
    };
  },
};
</script>
<style scoped>
ul {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 14px;
}
ul > li .card {
  min-width: 310px;
  height: 190px;
  background-color: var(--light-color);
  display: flex;
  gap: 6px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
    rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  border-radius: 12px;
  cursor: pointer;
  text-decoration: none;
}
ul > li .card .logo {
  aspect-ratio: 1 / 1;
  width: 65px;
  height: 64px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 8px;
}
.gradient {
  background: linear-gradient(
    -160deg,
    var(--primary-gradient) 0%,
    var(--secondary-gradient) 90%
  );
}
.svg {
  fill: var(--light-color);
}
ul > li .card h4 {
  font-weight: 500;
  color: var(--font-color-one);
}
ul > li .card h5 {
  font-weight: 400;
  color: var(--font-color-three);
}
</style>
