<template>
  <global-modal
    :modelValue="isActive"
    :title="$route.meta.subtitle"
    @modalHandler="getData"
  >
    <template #body>
      <!-- ============================
        Start Code Design Here
================================ -->
      <div class="main-modal">
        <form @submit.prevent="submitHandler">
          <div class="form-group-modal">
            <label for="Company">Company</label>
            <input
              type="text"
              v-model="company"
              required
              placeholder="Search Company..."
              @input="getCompanies(company)"
            />
            <div class="autofill">
              <ul v-if="itemCompanies && company">
                <li
                  class="filter-company"
                  v-for="(item, index) in itemCompanies"
                  :key="index"
                  @click="selectedHandler(item.Companies)"
                >
                  {{ item.Companies }}
                </li>
              </ul>
            </div>
          </div>
          <div class="form-group-modal-grid">
            <label for="Date">Frequency</label>
            <select
              v-model="selectedFrequency"
              @change="frequencyHandler()"
              required
            >
              <option disabled value="">--Please select--</option>
              <option
                v-for="(item, index) in itemFrequency"
                :key="index"
                :value="item.value"
              >
                {{ item.description }}
              </option>
            </select>
          </div>
          <div
            class="form-group-modal-grid"
            v-if="selectedFrequency === 'RD' ? true : false"
          >
            <label for="Date">Range Date</label>
            <input type="date" v-model="dateFrom" required />
            <input type="date" v-model="dateTo" required />
          </div>
          <div class="form-group-modal">
            <label for="Type Report">Type of Report</label>
            <select name="" id="">
              <option
                v-for="(item, index) in itemReportType"
                :key="index"
                :value="item"
              >
                {{ item }}
              </option>
            </select>
          </div>
          <div class="form-group-modal input-field loading-container">
            <global-loading v-if="isLoading" />
            <input
              type="submit"
              class="btn-submit gradient"
              :value="!isLoading ? 'Generate' : ''"
            />
          </div>
        </form>
      </div>
    </template>
  </global-modal>
</template>
<script>
import UsedFrequencyUtils from "@/utils/frequency.js";
import { computed, inject, reactive, ref, toRefs } from "vue";
export default {
  props: {
    isModal: Boolean,
  },
  setup(props, { emit }) {
    const axios = inject("$axios");
    const { selectedFrequency, dateFrom, dateTo, frequencyHandler } =
      UsedFrequencyUtils();
    const formData = reactive({
      company: null,
      frequency: null,
      dateFrom: null,
      dateTo: null,
    });
    const itemFrequency = ref([
      {
        description: "Last 3 Months",
        value: "3",
      },
      {
        description: "Last 6 Months",
        value: "6",
      },
      {
        description: "Last 12 Months",
        value: "12",
      },
      {
        description: "Year to Date",
        value: "YTD",
      },
      {
        description: "Range Date",
        value: "RD",
      },
    ]);
    const isLoading = ref(false);
    const itemReportType = ref(["Regular UR"]);
    const itemCompanies = ref([]);
    const isActive = computed(() => {
      return props.isModal;
    });
    const getData = (val) => {
      emit("updateModal", val);
    };
    const getCompanies = async (company) => {
      try {
        const value = company;
        const { data } = await axios.get(`/company`, {
          params: {
            name: value,
          },
        });
        itemCompanies.value = data;
      } catch (error) {
        console.error("Error fetching transactions:", error);
      }
    };
    const selectedHandler = (val) => {
      formData.company = val;
      itemCompanies.value = [];
    };
    const submitHandler = async () => {
      formData.frequency = selectedFrequency.value;
      formData.dateFrom = dateFrom.value;
      formData.dateTo = dateTo.value;

      isLoading.value = true;
      try {
        const { data } = await axios.get(`/companyur`, {
          params: {
            companyname: formData.company,
            dateFrom: formData.dateFrom,
            dateTo: formData.dateTo,
          },
        });
        if (data) {
          isLoading.value = false;

          let details = {
            companyname: formData.company,
            dateFrom: formData.dateFrom,
            dateTo: formData.dateTo,
            status: data.status,
          };
          emit("getDataResult", data, details);
          emit("updateModal", false);
        }
      } catch (error) {
        console.error("Error fetching transactions:", error);
      }
    };

    return {
      ...toRefs(formData),
      selectedFrequency,
      dateFrom,
      dateTo,
      itemFrequency,
      itemReportType,
      isActive,
      getData,
      submitHandler,
      frequencyHandler,
      getCompanies,
      itemCompanies,
      selectedHandler,
      isLoading,
    };
  },
};
</script>
<style scoped>
.autofill {
  position: relative;
}
ul {
  box-shadow: rgba(14, 63, 126, 0.04) 0px 0px 0px 1px,
    rgba(42, 51, 69, 0.04) 0px 1px 1px -0.5px,
    rgba(42, 51, 70, 0.04) 0px 3px 3px -1.5px,
    rgba(42, 51, 70, 0.04) 0px 6px 6px -3px,
    rgba(14, 63, 126, 0.04) 0px 12px 12px -6px,
    rgba(14, 63, 126, 0.04) 0px 24px 24px -12px;
  background-color: var(--light-color);
  border-radius: 4px;
  position: absolute;
  width: 545px;
  max-height: 285px;
  overflow-y: auto;
  z-index: 1000;
  left: 138px;
}
.filter-company {
  font-size: 15px;
  line-height: 22px;
  padding: 3px 8px;
  color: var(--font-color-four);
}

.filter-company:hover {
  background-color: var(--primary-gradient);
  color: var(--light-color);
}
.form-group-modal input[type="submit"] {
  color: var(--light-color);
  margin-top: 0px;
  height: 44px;
}
.loading-container {
  margin: 20px 0px;
}
</style>
