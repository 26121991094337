<template>
  <header>
    <HamburgerMenuSVG
      @click="$emit('toggle', ($store.state.isToggle = !$store.state.isToggle))"
      class="menu"
    />
    <img :src="require('@/assets/images/iwc-header.png')" alt="icon" />
    <LogoutSVG class="logout" @click="logoutHandler()" />
  </header>
</template>
<script>
import HamburgerMenuSVG from "@/components/svgs/HamburgerMenuIcon.vue";
import LogoutSVG from "@/components/svgs/LogoutIcon.vue";
import { inject } from "vue";
export default {
  components: {
    LogoutSVG,
    HamburgerMenuSVG,
  },
  setup() {
    const axios = inject("$axios");
    const router = inject("$router");
    const store = inject("$store");
    const logoutHandler = async () => {
      try {
        await axios.post(`portal/authorization/logout`);
        store.commit("authorizedModified");
        return router.push("/");
      } catch (err) {
        console.log(err);
      }
    };

    return {
      logoutHandler,
    };
  },
};
</script>
<style scoped>
header {
  height: 75px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--light-color);
  box-shadow: rgba(27, 31, 35, 0.04) 0px 1px 0px,
    rgba(255, 255, 255, 0.25) 0px 1px 0px inset;
  padding: 0px 20px 0px 5px;
}
header img {
  height: 75px;
  width: 290px;
}
header .logout {
  cursor: pointer;
}
header .menu {
  display: none;
}
@media only screen and (min-width: 320px) and (max-width: 440px) {
  header .menu {
    display: block;
  }
}
</style>
