import { createStore } from "vuex";
import persistedStateVuex from "persisted-state-vuex";
import FetchErrorHandler from "@/utils/errorHandler";

export default createStore({
  state: {
    adminLogDetails: {
      id: null,
      name: null,
    },
    isAuthorized: false,
  },
  getters: {},
  mutations: {
    getAdminLogDetails(state, val) {
      state.adminLogDetails.name = val.name;
      state.isAuthorized = true;
    },
    authorizedModified(state) {
      state.isAuthorized = false;
    },
    errorHandler(state, err) {
      const { ErrorAlertNotification } = FetchErrorHandler();
      ErrorAlertNotification(err);
    },
  },
  actions: {},
  modules: {},
  plugins: [persistedStateVuex.init],
});
