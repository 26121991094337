<template>
  <div class="container">
    <HeroSidebar :class="{ activeToggle: $store.state.isToggle }" />
    <section class="main-content">
      <HeroHeader />
      <section class="content-area">
        <div class="header-title">
          <h3>{{ $route.meta.title }}</h3>
          <h3 class="subtitle">
            {{ $route.meta.subtitle ? ` > ${$route.meta.subtitle}` : "" }}
          </h3>
        </div>
        <div class="panel">
          <router-view />
        </div>
      </section>
    </section>
  </div>
</template>

<script>
import HeroSidebar from "@/components/common/HeroSidebar.vue";
import HeroHeader from "@/components/common/HeroHeader.vue";

export default {
  components: { HeroSidebar, HeroHeader },
  setup() {
    return {};
  },
};
</script>

<style scoped>
.container {
  display: flex;
  min-height: 100vh;
}
.main-content {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.content-area {
  margin: 25px 20px;
}
.content-area .header-title {
  display: flex;
  align-items: center;
}
.content-area .header-title h3 {
  color: var(--font-color-four);
  font-weight: 500;
  font-size: 16px;
}
.content-area .header-title .subtitle {
  color: var(--font-color-three);
}
.content-area .panel {
  margin-top: 12px;
}
</style>
