<template>
  <div class="container">
    <div class="gradient" @click="$emit('routeHandler', true)">
      <span class="filter">
        <FilterSVG />
        Filter
      </span>
    </div>
    <div class="search">
      <div class="search-filter">
        <SearchSVG class="icon" />
        <input
          v-model="searchEngine"
          type="text"
          name="search"
          id=""
          placeholder="search..."
          @input="$emit('searchHandler', searchEngine)"
        />
      </div>
    </div>
  </div>
</template>
<script>
import FilterSVG from "@/components/svgs/FilterIcon.vue";
import SearchSVG from "@/components/svgs/SearchIcon.vue";
import { ref } from "vue";
export default {
  components: { FilterSVG, SearchSVG },
  setup() {
    const isClick = ref(false);
    const searchEngine = ref();
    return {
      isClick,
      searchEngine,
    };
  },
};
</script>
<style scoped>
.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
}
.filter {
  height: 40px;
  width: 90px;
  border-radius: 8px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  color: var(--light-color);
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.search {
  display: flex;
  gap: 8px;
  align-items: center;
}
.search-filter {
  position: relative;
  display: flex;
  align-items: center;
}
.search input {
  min-width: 360px;
  padding-left: 38px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.icon {
  position: absolute;
  left: 8px;
}
.gradient {
  border-radius: 8px;
}
.filter:hover {
  box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
  background: rgba(255, 255, 255, 0.329);
}
</style>
