<template>
  <div class="container-panel">
    <HeroFilter @routeHandler="getDataHandler" @searchHandler="getSearchData" />
    <IPOPDataGridTable
      :dataResult="itemResult"
      :dataDetails="itemFormData"
      :searchData="searchValue"
    />
    <AvailmentReportModal
      :isModal="isCurrentActive"
      @updateModal="isCurrentActive = closeModal"
      @getDataResult="passData"
    />
  </div>
</template>
<script>
import HeroFilter from "@/components/common/HeroFilter.vue";
import IPOPDataGridTable from "@/components/partials/reports/availment-report/IPOPDataGridTable.vue";
import AvailmentReportModal from "@/components/partials/modals/AvailmentReportModal.vue";
import { ref } from "vue";
export default {
  components: {
    HeroFilter,
    AvailmentReportModal,
    IPOPDataGridTable,
  },
  setup() {
    const isCurrentActive = ref(false);
    const searchValue = ref();
    const itemResult = ref([]);
    const itemFormData = ref([]);
    const totalPages = ref();
    const currentPages = ref();
    const getDataHandler = (result) => {
      isCurrentActive.value = result;
    };
    const getSearchData = (search) => {
      searchValue.value = search;
    };

    const passData = (data, form) => {
      itemResult.value = data;
      itemFormData.value = form;
    };

    return {
      getDataHandler,
      getSearchData,
      passData,
      isCurrentActive,
      itemResult,
      itemFormData,
      searchValue,
      totalPages,
      currentPages,
    };
  },
};
</script>
