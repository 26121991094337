<template>
  <aside class="sidebar gradient">
    <div class="profile">
      <div class="avatar">
        <img :src="require('@/assets/images/sample.png')" alt="icon" />
      </div>
      <div class="details">
        <span class="name">{{ $store.state.adminLogDetails.name }}</span>
        <span class="position">Chief Executive Officer</span>
      </div>
    </div>
    <nav>
      <ul>
        <li v-for="(item, index) in itemCategories" :key="index">
          <div :class="{ active: $route.meta.main === item.name }">
            <router-link v-if="item.path" :to="item.path" class="nav-menu">
              <component :is="item.icon" class="icon"></component>
              {{ item.name }}
            </router-link>
            <a v-else href="#" @click="item.action" class="nav-menu">
              <DashboardIcon class="icon" /> {{ item.name }}
            </a>
          </div>
        </li>
      </ul>
    </nav>
  </aside>
</template>
<script>
import DashboardSVG from "@/components/svgs/DashboardIcon.vue";
import ReportSVG from "@/components/svgs/ReportIcon.vue";
import { reactive } from "vue";
export default {
  components: {
    DashboardSVG,
    ReportSVG,
  },
  props: {
    isToggle: Boolean,
  },
  setup() {
    const itemCategories = reactive([
      {
        name: "Dashboard",
        icon: "DashboardSVG",
        path: "/dashboard",
      },
      {
        name: "Reports",
        icon: "ReportSVG",
        path: "/reports",
      },
    ]);

    return {
      itemCategories,
    };
  },
};
</script>

<style scoped>
.sidebar {
  flex-shrink: 0;
  min-width: 310px;
  display: flex;
  flex-direction: column;
}
.gradient {
  background: linear-gradient(
    170deg,
    var(--primary-gradient) -25%,
    var(--secondary-gradient) 40%
  );
}
.profile {
  margin: 50px 20px;
  display: flex;
  gap: 15px;
  align-items: center;
}
.profile .avatar img {
  height: 65px;
  width: 70px;
}
.profile .details {
  display: flex;
  flex-direction: column;
}
.profile .details .name {
  color: var(--light-color);
  font-size: 18px;
  font-weight: 600;
}
.profile .details .position {
  color: var(--font-color-two);
  font-size: 15px;
  font-weight: 600;
}
nav {
  margin: 0px 20px;
}
nav ul {
  list-style: none;
  padding: 0;
}
nav ul li {
  margin: 10px 0;
  border-radius: 8px;
}
nav ul li:hover {
  box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
  background: rgb(255, 255, 255, 0.12);
}
nav ul li a {
  text-decoration: none;
  color: var(--semi-light-color);
  font-size: 1em;
  transition: color 0.3s ease;
  padding: 12px 22px;
}
nav ul li a:hover {
  color: var(--light-color);
}
.nav-menu {
  display: flex;
  align-items: center;
  gap: 5px;
}
.active > a {
  color: var(--light-color);
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
  background: rgb(255, 255, 255, 0.25);
}
</style>
